import React, { useMemo } from "react";
import blobUrlFormatHelper from "../../components/blobUrlFormatHelper";
import { ElementTypes, IBasicPageImageV2 } from "../../pageTypes/BasicPage_Player/components/IBasePage";
import AnnotationHighlight from "../../pageTypes/BasicPage_Player/components/BaseFreeForm/components/HighlightRect";
import { useTimeline } from "../../contexts/TimelineProvider/TimelineProvider";
import { IKeyPressHandler } from "../../components/ObjectPropertyBox/models/IKeyPressHandler";
import { ObjectActionsType, useObjectsDispatch, useObjectsState } from "../../contexts/ObjectsProvider";
import type { AllPartialBut, BaseObject, Frame, MakePropertiesRequired, RequiredProperty } from "../../types";
import { useInterpolatedFrame } from "../../hooks/useInterpolatedFrame";
import { transform } from "lodash";
import { percentageToValue, valueToPercentage } from "../../utils/Conversion";
import { useMovableElementsPlaneState } from "../../contexts/MovableElementsPlaneProvider";
import { useObjectPosition } from "../../hooks/useObjectPosition";
import { useInteracitvityHotspotDispatch } from "../../contexts/InteractivityHotspotProvider";
import { InteractivityHotspotActionTypes } from "../../contexts/InteractivityHotspotProvider/types";
import { useObjectIsInTime } from "../../hooks/useObjectIsInTime";
import { objectOpacityFromRules } from "../../utils";
export interface ImagePropType {
  blobUrl: string;
  grayscale?: number;
  ffElement: IBasicPageImageV2;
  index: number;
  frames?: any[];
  framesCacheId?: BaseObject["framesCacheId"];
  kp: IKeyPressHandler;
  target: any;
  objectId: string;

  scale?: any[];
  rotate?: number;
  zIndex?: number;

  pixelTop?: number;
  pixelLeft?: number;
  pixelWidth?: number;
  pixelHeight?: number;
  clipTop?: number;
  clipLeft?: number;
  clipRight?: number;
  clipBottom?: number;
  clipPath?: string;

  maintainRatio?: boolean;

  height?: number;
  width?: number;
  left: number;
  top: number;

  version?: any;

  fontColor?: string;
  fontStyle?: string;
  fontWeight?: any;

  borderColor?: string;
  shadowColor?: string;
  textDecoration?: string;
  backgroundColor?: string;
  opacity?: number;
  visible?: boolean;
  ghost?: boolean;
  // transformOrigin?: string;

  handleKeyPress: (e: React.KeyboardEvent<HTMLDivElement | SVGSVGElement>, kp: IKeyPressHandler) => void;
  loadImage: (e: React.SyntheticEvent<HTMLImageElement>, index: number, objectId: string) => void;
  selectTarget: (
    target: HTMLDivElement,
    index: number,
    type: ElementTypes,
    ref?: React.RefObject<HTMLElement | SVGSVGElement>,
  ) => void;
  handleImageDivClick: (index: number) => void;
}
const Image = (props: ImagePropType): JSX.Element => {
  const {
    blobUrl,
    index,
    loadImage,
    objectId,
    scale,
    clipTop,
    clipLeft,
    clipRight,
    clipBottom,
    fontColor,
    fontStyle,
    fontWeight,
    borderColor,
    shadowColor,
    version,
    width,
    left,
    top,
    rotate,
    height,
    maintainRatio,
    zIndex,
    textDecoration,
    backgroundColor,
    clipPath,
    opacity,
    visible,
    ghost,
    // transformOrigin,
  } = props;
  const scaleString: string = scale ? `scale(${scale[0]}, ${scale[1]})` : "";
  const isVersion4 = Number(version) >= 4;
  const {
    position: [x, y],
    size: [widthWithFrames, heightWithFrames],
    rotation,
    opacity: opacityValue,
  } = useObjectPosition(objectId, top, left, width, height, rotate, opacity);
  const isInTime = useObjectIsInTime(objectId);
  const rotateString: string = rotation ? `rotate(${rotation}deg)` : "rotate(0deg)";
  const hasLegacyClipPath = ![clipTop, clipLeft, clipRight, clipBottom].every((c) => c === 0 || c === undefined);
  const clipP =
    hasLegacyClipPath && !clipPath
      ? `inset(${clipTop}px ${clipRight}px ${clipBottom}px ${clipLeft}px)`
      : clipPath
      ? clipPath
      : "";
  const objectsDispatch = useObjectsDispatch();
  const { selectedObjects } = useObjectsState();
  const hotspotsDispatch = useInteracitvityHotspotDispatch();

  const transformString = `translate(${x}px, ${y}px) ${rotateString}`;
  // if in timeline opacity is 0

  return (
    <div
      className={`target ff-image guide-${objectId}`}
      id={`image-target-${index}`}
      data-objectid={objectId}
      key={index}
      onMouseDown={(e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        // if (e.currentTarget !== target) {
        //   selectTarget(e.currentTarget, index, 'pageImage' as ElementTypes);
        // }
        // if control key is pressed, add to selection
        if (!objectId) return;
        let type = ObjectActionsType.SET_SELECTED_OBJECT;
        if (e.ctrlKey) {
          type = ObjectActionsType.ADD_SELECTED_OBJECT;
        }
        objectsDispatch({
          type,
          payload: { objectId },
        });
        hotspotsDispatch({
          type: InteractivityHotspotActionTypes.SET_CURRENT_HOTSPOT,
          payload: null,
        });
        // selectedObjectDispatch({
        //   type: SelectedObjectActionTypes.SET_X,
        //   payload: ,
        // })
      }}
      onDoubleClick={() => props.handleImageDivClick(index)}
      // onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) =>
      //   handleKeyPress(e, kp)
      // }
      // onKeyUp={(e: React.KeyboardEvent<HTMLDivElement>) => handleKeyUp(e, kp)}
      tabIndex={0}
      style={{
        position: "absolute",
        width: `${widthWithFrames}px`,
        height: `${heightWithFrames}px`,
        clipPath: clipP,
        zIndex: zIndex,
        scale: `${scaleString}`,
        // rotate: `${rotateString}`,
        transform: transformString,
        // translate: `${leftWithFrames}px`,
        color: `${fontColor}`,
        fontStyle: `${fontStyle}`,
        fontWeight: fontWeight,
        textDecoration: `${textDecoration}`,
        backgroundColor: `${backgroundColor}`,
        outline: `${borderColor}`,
        boxShadow: `${shadowColor}`,
        opacity: objectOpacityFromRules(opacityValue, visible, isInTime, ghost),
        filter: visible ? "none" : "grayScale(100%)",
        // transformOrigin: `${transformOrigin}`,
      }}
    >
      <AnnotationHighlight objectId={objectId} />
      <div id="free-form-container" className="img-aspect-container">
        <div className="free-form-element aspect-container-inside">
          <div className="free-form-inner aspect-centering">
            <img
              id={`ff-image-${index}`}
              src={blobUrlFormatHelper(blobUrl)}
              alt=""
              onLoad={(e: React.SyntheticEvent<HTMLImageElement>) => {
                // we only want this to trigger if there is no version or if the version is less than 3
                if (!version || Number(version) < 3) {
                  loadImage(e, index, objectId);
                }
              }}
              style={{
                display: isInTime || ghost ? "block" : "none",
                width: "100%",
                height: maintainRatio ? "auto" : "100%",
                filter: typeof props.grayscale === "number" ? `grayscale(${props.grayscale}%)` : undefined,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// cubic bezier curve, future use
// function cubicBezier(p0: number, p1: number, p2: number, p3: number, t: number) {
//   const t2 = t * t;
//   const t3 = t2 * t;
//   return (
//     p0 * (1 - t) * (1 - t) * (1 - t) +
//     3 * p1 * (1 - t) * (1 - t) * t +
//     3 * p2 * (1 - t) * t2 +
//     p3 * t3
//   );
// }
// function cubicBezier(p0:number, p1:number, p2:number, p3:number, t:number) {
//   return (
//     Math.pow(1 - t, 3) * p0 +
//     3 * Math.pow(1 - t, 2) * t * p1 +
//     3 * (1 - t) * Math.pow(t, 2) * p2 +
//     Math.pow(t, 3) * p3
//   );
// }
// function interpolateWithBezier(frame1: any, frame2: any, currentTime:number, controlPoint1:number, controlPoint2:number) {

//   const t = (currentTime - frame1.timestamp) / (frame2.timestamp - frame1.timestamp);
//   const interpolatedX = cubicBezier(frame1.x, controlPoint1, controlPoint2, frame2.x, t);

//   const interpolatedFrame = {
//     timestamp: currentTime,
//     x: interpolatedX,
//   };

//   return interpolatedFrame;
// }

function interpolate(value1: number, value2: number, value1T: number, value2T: number, t: number) {
  return value1 + ((value2 - value1) * (t - value1T)) / (value2T - value1T);
}

export function interpolateFrame(framesBehind: Frame[], framesAhead: Frame[], currentTime: number) {
  function interpolateProperty<K extends keyof Omit<Frame, "id">>(property: K): number | undefined {
    const framesBehindWithProperty = framesBehind.filter((frame) => typeof frame[property] === "number");
    const framesAheadWithProperty = framesAhead.filter((frame) => typeof frame[property] === "number");

    if (framesBehindWithProperty.length > 0 && framesAheadWithProperty.length > 0) {
      return interpolate(
        framesBehindWithProperty[framesBehindWithProperty.length - 1][property]!,
        framesAheadWithProperty[0][property]!,
        framesBehindWithProperty[framesBehindWithProperty.length - 1].timestamp,
        framesAheadWithProperty[0].timestamp,
        currentTime,
      ) as any;
    } else if (framesBehindWithProperty.length === 0 && framesAheadWithProperty.length === 0) {
      return undefined;
    } else if (framesBehindWithProperty.length === 0) {
      return framesAheadWithProperty[0][property];
    } else if (framesAheadWithProperty.length === 0) {
      return framesBehindWithProperty[framesBehindWithProperty.length - 1][property];
    }
  }
  // a frame can be missing x or y
  const interpolatedFrame: Omit<Frame, "id"> = {
    timestamp: currentTime,
    x: interpolateProperty("x"),
    y: interpolateProperty("y"),
    width: interpolateProperty("width"),
    height: interpolateProperty("height"),
    rotation: interpolateProperty("rotation"),
    opacity: interpolateProperty("opacity"),
  };
  return interpolatedFrame;
}

export function getFramesNearCurrentTime(frames: Frame[] | undefined, currentTime: number) {
  if (!frames) return null;
  if (frames.length === 0) return null;
  //TODO ensure frames are sorted before here
  const sortedFrames = [...frames].sort((a: any, b: any) => a.timestamp - b.timestamp);
  const framesBehind = [];
  const framesAhead = [];
  for (let i = 0; i < sortedFrames.length; i++) {
    if (sortedFrames[i].timestamp <= currentTime) {
      framesBehind.push(sortedFrames[i]);
    } else {
      framesAhead.push(sortedFrames[i]);
    }
  }

  /**  we pass in the two frames to compare, but either could be missing a property the other has, we need to handle this case
   *
   * frame 1 {
   *  timestamp: 0,
   *  x: 15,
   *  y: 12,
   *  }
   * frame 2 {
   * timestamp: 1000,
   * x: 20,
   * y: undefined, -> this is the case we need to handle, we need to go and find the next frame that has a y value and use it, for the interploation result of this frame, incurrs a performance cost, we can avoid the cost by interpolating between frame 1 and frame 3, but we need to handle the case where frame 3 is missing a property
   * }
   * frame 3 {
   * timestamp: 2000,
   * x: undefined,
   * y: 30,
   *
   *
   * now we want to find the y value at 0500
   *
   *  we need to interpolate between frame 1 and frame 2, but we need to handle the case where frame 2 is missing a property
   *  you can not interpolate between two points if one of the points is missing a property so we need to grab that property from the next frame that has it
   *
   *
   */

  const interpolatedFrame = interpolateFrame(framesBehind, framesAhead, currentTime);

  return interpolatedFrame;
}

export default Image;
